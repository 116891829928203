export const initializeSocialSignIn = function () {
    window.onGoogleSignIn = function (response) {
        if (response) {
            const responsePayload = JSON.parse(atob(response.credential.split('.')[1]));

            const email = responsePayload.email;
            const givenName = responsePayload.given_name;
            const familyName = responsePayload.family_name;
            const accessToken = response.credential;

            // Montar a URL de redirecionamento
            const currentUrl = new URL(window.location.href);
            const realBackUrl = (currentUrl.pathname || '').substring(1);
            const backParam = currentUrl.searchParams.get('back');

            let newUrl = `/go-login.php?email=${email}&given_name=${givenName}&family_name=${familyName}&access_token=${accessToken}`;
            if (realBackUrl && realBackUrl !== '') {
                newUrl += '&back=' + (backParam && backParam !== '' ? backParam : realBackUrl);
            }

            // Redirecionar para a nova URL
            window.location.href = newUrl;
        }
    };

    window.onFacebookSignIn = function (data) {
        if ((data || {}).authResponse) {
            const currentUrl = new URL(window.location.href);
            const realBackUrl = (currentUrl.pathname || '').substring(1);
            const backParam = currentUrl.searchParams.get('back');

            let newUrl = '/fb-login.php?facebook_token=' + data.authResponse.accessToken;
            if (realBackUrl && realBackUrl !== '') {
                newUrl += '&back=' + (backParam && backParam !== '' ? backParam : realBackUrl);
            }

            window.location.href = newUrl;
        }
    };
};
