import '../common/base';
import Vue from './common/vue-common';
import Cart from './components/cart/Cart.vue';
import VueMask from 'v-mask';
import locale from './i18n/locale';
import shop from './i18n/shop';
import general from './i18n/general';
import cart from './i18n/cart';
import date from './i18n/date';

Vue.use(VueMask);

new Vue({
    el: '#CartInstance',
    template: '<Cart/>',
    components: { Cart },
    i18n: locale(general(), cart(), shop(), date()),
});
